import { SITE } from '~/utils/config';

const trim = (str: string, ch?: '/'): string => {
	let start = 0,
		end = str.length || 0;
	while (start < end && str[start] === ch) ++start;
	while (end > start && str[end - 1] === ch) --end;
	return start > 0 || end < str.length ? str.substring(start, end) : str;
};

const trimSlash = (s: string): string => trim(trim(s, '/'));

const createPath = (...params: any[]) => {
	const paths = params.filter((el) => !!el).join('/');
	return '/' + paths + (SITE.trailingSlash && paths ? '/' : '');
};

const basePathname = trimSlash(SITE.basePathname);

/** */
export const getPermalink = (slug?: string) => {
	return createPath(basePathname, slug);
};

/** */
export const getHomePermalink = () => {
	const permalink = getPermalink();
	return permalink !== '/' ? permalink + '/' : permalink;
};

/** */
export const getRelativeLink = (link = '') => {
	return createPath(basePathname, trimSlash(link));
};

/** */
export const getBlogPermalink = (): URL => SITE.blogPageURL;

/** */
export const getCareersPermaLink = (): URL => SITE.careersPageURL;
