<script>
	export let classList;
</script>

<svg
	class={classList}
	viewBox="0 0 192 61"
	fill="currentColor"
	xmlns="http://www.w3.org/2000/svg"
	role="img"
	aria-label="Cityflo Logo"
>
	<title>Cityflo Logo</title>
	<path d="M38.7448 14.906H32.7148V45.9052H38.7448V14.906Z" fill="currentColor" />
	<path d="M138.432 0H132.438V45.9056H138.432V0Z" fill="currentColor" />
	<path
		d="M50.9783 37.1516V20.8359H44.3047V14.9064H48.8542C50.0274 14.9064 50.9783 13.9658 50.9783 12.8054V0H56.9731V14.9064H63.7695V20.8359H56.9731V36.4744C56.9731 38.4104 58.5588 39.976 60.5132 39.976H64.4629V45.9056H59.8315C54.9426 45.9056 50.9783 41.9843 50.9783 37.1487"
		fill="currentColor"
	/>
	<path
		d="M110.87 45.9056V22.9369C110.87 21.7764 109.919 20.8359 108.746 20.8359H104.887V14.9064H110.87V8.79449C110.87 3.98487 114.79 0.0781346 119.653 0.0376204L124.354 0V6.03952H120.408C118.453 6.03952 116.868 7.608 116.868 9.54111V14.9035H124.357V20.833H116.868V45.9027H110.873L110.87 45.9056Z"
		fill="currentColor"
	/>
	<path
		d="M0 30.7558V30.0554C0 21.6893 6.85789 14.906 15.3162 14.906H25.1934V20.8356H15.6731C10.3278 20.8356 5.99773 25.1214 5.99773 30.4056C5.99773 35.6898 10.3307 39.9757 15.6731 39.9757H25.1934V45.9052H15.3162C6.85789 45.9052 0 39.122 0 30.7558Z"
		fill="currentColor"
	/>
	<path
		d="M146.348 30.4056C146.348 21.8455 153.364 14.906 162.018 14.906H176.328C184.982 14.906 191.998 21.8455 191.998 30.4056C191.998 38.9657 184.982 45.9052 176.328 45.9052H162.018C153.364 45.9052 146.348 38.9657 146.348 30.4056ZM162.021 39.9757H176.325C181.67 39.9757 186 35.6898 186 30.4056C186 25.1214 181.667 20.8356 176.325 20.8356H162.021C156.675 20.8356 152.345 25.1214 152.345 30.4056C152.345 35.6898 156.678 39.9757 162.021 39.9757Z"
		fill="currentColor"
	/>
	<path
		d="M70.8067 60.8116V55.5592H74.0572C76.2223 55.5592 78.1708 54.2598 78.9812 52.2717L81.4798 46.1541L68.7148 14.906H74.6541L84.4494 38.8847L94.2418 14.906H100.181L87.4717 46.021L84.1159 54.2425C82.495 58.2158 78.5979 60.8145 74.2649 60.8145H70.8067V60.8116Z"
		fill="currentColor"
	/>
</svg>
